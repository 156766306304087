import SecureLS from 'secure-ls';
import { AuthUrls } from 'services/api/urls';
import { getToken } from 'contexts/AuthContext';

const ls = new SecureLS({ encodingType: 'aes' });

class BaseService {
  static getHeaders = (isFile?: boolean) => {
    const headers = new Headers();
    if (!isFile) {
      headers.append('Content-Type', 'application/json');
    }
    headers.append('Accept', 'application/json');
    headers.append('Origin', '*');
    const lang = ls.get('lang') || 'fr';
    headers.append('Accept-Language', lang);
    return headers;
  };

  static renewToken = async () => {
    try {
      const res = await BaseService.getRequest(AuthUrls.REFRESH_TOKEN, true);
      if (res.status === 200 || res.status === 201) {
        const data = await res.json();
        ls.set('token', data.jwt);
      }
    } catch (e) {
      console.warn('Erreur lors du renouvellement du token', e);
    }
  };

  static getHeadersAuth = (isFile?: boolean) => {
    const headers = BaseService.getHeaders(isFile);
    const token = getToken();
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
  };

  static request = async (
    url: string,
    method: string,
    requiredAuth?: boolean,
    body?: any,
    isMultipart?: boolean,
  ): Promise<Response> => {
    const headers = requiredAuth
      ? BaseService.getHeadersAuth(isMultipart)
      : BaseService.getHeaders(isMultipart);

    try {
      return await fetch(url, {
        method,
        headers,
        mode: 'cors' as const,
        cache: 'default' as const,
        ...(body
          ? isMultipart
            ? { body }
            : { body: JSON.stringify(body) }
          : {}),
      });
    } catch (err: any) {
      return err;
    }
  };

  static postRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'POST', requiredAuth, body);
  };

  static postFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'POST', requiredAuth, body, true);
  };

  static putFileRequest = async (
    url: string,
    body: FormData,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PUT', requiredAuth, body, true);
  };

  static putRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PUT', requiredAuth, body);
  };

  static patchRequest = async (
    url: string,
    body: object,
    requiredAuth?: boolean,
  ) => {
    return BaseService.request(url, 'PATCH', requiredAuth, body);
  };

  static deleteRequest = async (url: string, requiredAuth?: boolean) => {
    return BaseService.request(url, 'DELETE', requiredAuth);
  };

  static getRequest = async (url: string, requiredAuth?: boolean) => {
    return BaseService.request(url, 'GET', requiredAuth);
  };
}

export interface requestInterface {
  url?: string;
  body?: object;
  requiredAuth?: boolean;
}

export default BaseService;
